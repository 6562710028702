import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {useIntl} from 'gatsby-plugin-intl';
import Text from 'antd/es/typography/Text';
import styles from './Post.module.less';

const Post = ({post, path}) => {
    const {locale} = useIntl();

    return (
        <>
            <Link to={`/${locale}/${path}/${post.slug}`} className={styles.postWrapper}>
                <div className={`${styles.imageWrapper} ${path === 'product' ? styles.imageWrapperPlane : ''}`}>
                    <img
                        src={post.heroImage.fixed?.src || post.heroImage.file.url}
                        alt={post.title}
                        className={styles.image}
                    />
                </div>
                <div
                    className={styles.title}
                    style={{borderColor: post.category.color, color: post.category.color}}
                >
                    <span className="text">{post.title}</span>
                </div>
            </Link>
            <Text className={styles.content}>{post.excerpt.slice(0, 200)}...</Text>
        </>
    );
};

Post.propTypes = {
    post: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
}

export default Post;
