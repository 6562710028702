import React from 'react';
import {useIntl} from 'gatsby-plugin-intl';
import styles from './CategoryEmptyPanel.module.less';

const CategoryEmptyPanel = ({ activeCategory }) => {
    const {formatMessage} = useIntl();

    return (
        <>
            {activeCategory !== null
            && (
                <div className={styles.imageWrapper}>
                    <img
                        className={styles.emptyPostImage}
                        src={activeCategory.image?.fixed.src}
                        alt={activeCategory.title}
                    />
                </div>
            )}
            <h1 className={styles.emptyPostTitle}>{formatMessage({id: 'magazine.empty-category'})}</h1>
        </>
    );
};

export default CategoryEmptyPanel;
