import React from 'react';
import { Link } from 'gatsby';
import {useIntl} from 'gatsby-plugin-intl';

const CategoryLink = ({category, activeCategory}) => {
    const {locale, formatMessage} = useIntl();
    const classNameSuffix = category ? category.slug : 'default';
    const isActive = activeCategory === null || category?.slug === activeCategory?.slug;

    return (
        <Link
            to={`/${locale}/magazine/${category ? category.slug : ''}`}
            className={`category category-${classNameSuffix} category-${classNameSuffix}${isActive ? '-active' : ''}`}
        >
            {category?.title || formatMessage({id: 'all-posts'})}
        </Link>
    );
};

export default CategoryLink;
