import React from 'react';
import {graphql, Link} from 'gatsby';
import Layout from '../components/layout';
import Helmet from 'react-helmet';
import styles from '../components/category-page/CategoryPage.module.less'
import {useIntl} from 'gatsby-plugin-intl';
import {Col, Row} from 'antd';
import VisibilitySensor from '../components/VisibilitySensor';
import ArrowDownIcon from "../components/icons/ArrowDownIcon";
import {Tooltip} from "@material-ui/core";
import CategoryLink from "../components/category-link/CategoryLink";
import CategoryEmptyPanel from "../components/category-empty-panel/CategoryEmptyPanel";
import Post from "../components/post/Post";
import MagazineRequestSolution from "../components/magazine-request-solution/MagazineRequestSolution";

export default function Template({data, pageContext}) {
    const {category} = pageContext;
    const {formatMessage, locale} = useIntl();
    const articles = data.allContentfulArticle.edges.map(({node}) => node);

    const posts = data.allContentfulArticle.edges
        .filter(({node: post}) => category !== null ? post.category.slug === category.slug : true)
        .map(({node: job}) => job);

    const categories = data.allContentfulArticleCategory.edges
        .map(({node}) => node);
    const categorySlugs = categories.map(c => c.slug);

    const indexOfCurrentCategory = categories.reduce((categoryIndex, c, index) => {
        if (c.slug === category?.slug) {
            return index;
        }
        return categoryIndex;
    }, 0);

    const nextArticle = category === null
        ? articles[0]
        : (articles.find((a) => categorySlugs.indexOf(a.category.slug) >= indexOfCurrentCategory)
            || articles[0]);

    return (
        <Layout data={data}>
            <Helmet>
                <title>{formatMessage({id: 'magazine.page-title'})}</title>
                <meta name="description" content={formatMessage({id: 'magazine.page-description'})} />
            </Helmet>
            <div className={styles.substrate} style={{backgroundColor: category?.color || '#272727'}}>
                <Link to="#" className={styles.arrowLeft} disabled>
                    <ArrowDownIcon />
                </Link>
                <h1 className={`${styles.title} show-lg-down`}>{formatMessage({id: 'magazine.title'})}</h1>
                {
                    nextArticle &&
                    <Tooltip title={formatMessage({id: 'next-post-button.tooltip'})}>
                        <Link to={`/${locale}/magazine/${nextArticle.slug}`} className={styles.arrowRight}>
                            <ArrowDownIcon />
                        </Link>
                    </Tooltip>
                }
            </div>
            <div className={styles.section}>
                <h1 className={`${styles.title} show-lg-up`}>{formatMessage({id: 'magazine.title'})}</h1>
                <div className={styles.linkContainer}>
                    {[null].concat(categories).map((c) => (
                        <CategoryLink
                            key={c?.id || 'default'}
                            category={c}
                            activeCategory={category} />
                    ))}
                </div>
                {posts.length === 0 && <CategoryEmptyPanel activeCategory={category} />}
                {posts.length > 0 && posts.slice(0, 1).map((post) => (
                    <React.Fragment key={post.slug}>
                        <div className={styles.firstPostImageContainer}>
                            <Link to={`/${locale}/magazine/${post.slug}`}>
                                <img
                                    className={styles.firstPostImage}
                                    src={post.heroImage.fixed.src}
                                    alt={post.title}
                                />
                            </Link>
                        </div>
                        <Link
                            className={styles.firstPostTitle}
                            to={`/${locale}/magazine/${post.slug}`}>
                            {post.title}
                        </Link>
                        <p className={styles.excerpt}>{post.excerpt}</p>
                    </React.Fragment>
                ))}
                <VisibilitySensor>
                    <Row gutter={[{xs: 0, sm: 0, md: 60}, {xs: 30, sm: 30, md: 60}]}>
                        {posts.slice(1).map(post => (
                            <Col sm={12} md={6}>
                                <Post key={post.id} post={post} path="magazine" />
                            </Col>
                        ))}
                    </Row>
                </VisibilitySensor>
            </div>
            <VisibilitySensor>
                <MagazineRequestSolution />
            </VisibilitySensor>
        </Layout>
    );
};

export const pageQuery = graphql`
  query($locale: String) {
    allContentfulArticle(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]},
      filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }
    ) {
      edges {
        node {
          id
          title
          slug
          excerpt
          description { raw }
          heroImage {  
            fixed(width: 600, toFormat: PNG) {
              src
            }
          }
          category {
            slug
            color
          }
        }
      }
    }
    allContentfulArticleCategory(
      sort: {fields: createdAt, order: ASC}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          id
          title
          slug
          color
        }
      }
    }
    allContentfulStory(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulVacancy(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;
